export default function e() {
  return {
    validate(e) {
      if (e.value === '') {
        return { valid: true }
      }
      let t
      const i = e.options.extension ? e.options.extension.toLowerCase().split(',') : null
      const s = e.options.type ? e.options.type.toLowerCase().split(',') : null
      const n = window['File'] && window['FileList'] && window['FileReader']
      if (n) {
        const n = e.element.files
        const o = n.length
        let a = 0
        if (e.options.maxFiles && o > parseInt(`${e.options.maxFiles}`, 10)) {
          return { meta: { error: 'INVALID_MAX_FILES' }, valid: false }
        }
        if (e.options.minFiles && o < parseInt(`${e.options.minFiles}`, 10)) {
          return { meta: { error: 'INVALID_MIN_FILES' }, valid: false }
        }
        let r = {}
        for (let l = 0; l < o; l++) {
          a += n[l].size
          t = n[l].name.substr(n[l].name.lastIndexOf('.') + 1)
          r = { ext: t, file: n[l], size: n[l].size, type: n[l].type }
          if (e.options.minSize && n[l].size < parseInt(`${e.options.minSize}`, 10)) {
            return {
              meta: Object.assign({}, { error: 'INVALID_MIN_SIZE' }, r),
              valid: false,
            }
          }
          if (e.options.maxSize && n[l].size > parseInt(`${e.options.maxSize}`, 10)) {
            return {
              meta: Object.assign({}, { error: 'INVALID_MAX_SIZE' }, r),
              valid: false,
            }
          }
          if (i && i.indexOf(t.toLowerCase()) === -1) {
            return {
              meta: Object.assign({}, { error: 'INVALID_EXTENSION' }, r),
              valid: false,
            }
          }
          if (n[l].type && s && s.indexOf(n[l].type.toLowerCase()) === -1) {
            return {
              meta: Object.assign({}, { error: 'INVALID_TYPE' }, r),
              valid: false,
            }
          }
        }
        if (e.options.maxTotalSize && a > parseInt(`${e.options.maxTotalSize}`, 10)) {
          return {
            meta: Object.assign({}, { error: 'INVALID_MAX_TOTAL_SIZE', totalSize: a }, r),
            valid: false,
          }
        }
        if (e.options.minTotalSize && a < parseInt(`${e.options.minTotalSize}`, 10)) {
          return {
            meta: Object.assign({}, { error: 'INVALID_MIN_TOTAL_SIZE', totalSize: a }, r),
            valid: false,
          }
        }
      } else {
        t = e.value.substr(e.value.lastIndexOf('.') + 1)
        if (i && i.indexOf(t.toLowerCase()) === -1) {
          return { meta: { error: 'INVALID_EXTENSION', ext: t }, valid: false }
        }
      }
      return { valid: true }
    },
  }
}
