import e from '../utils/format'
export default function s() {
  return {
    validate(s) {
      if (s.value === '') {
        return { valid: true }
      }
      const a = Object.assign({}, { inclusive: true, message: '' }, s.options)
      const l = parseFloat(`${a.max}`.replace(',', '.'))
      return a.inclusive
        ? {
            message: e(s.l10n ? a.message || s.l10n.lessThan.default : a.message, `${l}`),
            valid: parseFloat(s.value) <= l,
          }
        : {
            message: e(s.l10n ? a.message || s.l10n.lessThan.notInclusive : a.message, `${l}`),
            valid: parseFloat(s.value) < l,
          }
    },
  }
}
