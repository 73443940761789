export default function e(e, t, r, c) {
  const n = (r.getAttribute('type') || '').toLowerCase()
  const a = r.tagName.toLowerCase()
  switch (a) {
    case 'textarea':
      return r.value
    case 'select':
      const e = r
      const t = e.selectedIndex
      return t >= 0 ? e.options.item(t).value : ''
    case 'input':
      if ('radio' === n || 'checkbox' === n) {
        const e = c.filter((e) => e.checked).length
        return e === 0 ? '' : e + ''
      } else {
        return r.value
      }
    default:
      return ''
  }
}
