import r from './between'
import m from './blank'
import i from './callback'
import o from './choice'
import t from './creditCard'
import e from './date'
import p from './different'
import f from './digits'
import n from './emailAddress'
import a from './file'
import s from './greaterThan'
import d from './identical'
import l from './integer'
import c from './ip'
import g from './lessThan'
import h from './notEmpty'
import b from './numeric'
import u from './promise'
import k from './regexp'
import C from './remote'
import T from './stringCase'
import x from './stringLength'
import w from './uri'
export default {
  between: r,
  blank: m,
  callback: i,
  choice: o,
  creditCard: t,
  date: e,
  different: p,
  digits: f,
  emailAddress: n,
  file: a,
  greaterThan: s,
  identical: d,
  integer: l,
  ip: c,
  lessThan: g,
  notEmpty: h,
  numeric: b,
  promise: u,
  regexp: k,
  remote: C,
  stringCase: T,
  stringLength: x,
  uri: w,
}
