import e from '../utils/format'
export default function s() {
  const s = (e) => parseFloat(`${e}`.replace(',', '.'))
  return {
    validate(a) {
      const t = a.value
      if (t === '') {
        return { valid: true }
      }
      const n = Object.assign({}, { inclusive: true, message: '' }, a.options)
      const l = s(n.min)
      const o = s(n.max)
      return n.inclusive
        ? {
            message: e(a.l10n ? n.message || a.l10n.between.default : n.message, [`${l}`, `${o}`]),
            valid: parseFloat(t) >= l && parseFloat(t) <= o,
          }
        : {
            message: e(a.l10n ? n.message || a.l10n.between.notInclusive : n.message, [`${l}`, `${o}`]),
            valid: parseFloat(t) > l && parseFloat(t) < o,
          }
    },
  }
}
