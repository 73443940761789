export default function a() {
  return {
    validate(a) {
      if (a.value === '') {
        return { valid: true }
      }
      const e = Object.assign({}, { decimalSeparator: '.', thousandsSeparator: '' }, a.options)
      let t = `${a.value}`
      if (t.substr(0, 1) === e.decimalSeparator) {
        t = `0${e.decimalSeparator}${t.substr(1)}`
      } else if (t.substr(0, 2) === `-${e.decimalSeparator}`) {
        t = `-0${e.decimalSeparator}${t.substr(2)}`
      }
      const r = e.decimalSeparator === '.' ? '\\.' : e.decimalSeparator
      const s = e.thousandsSeparator === '.' ? '\\.' : e.thousandsSeparator
      const i = new RegExp(`^-?[0-9]{1,3}(${s}[0-9]{3})*(${r}[0-9]+)?$`)
      const o = new RegExp(s, 'g')
      if (!i.test(t)) {
        return { valid: false }
      }
      if (s) {
        t = t.replace(o, '')
      }
      if (r) {
        t = t.replace(r, '.')
      }
      const l = parseFloat(t)
      return { valid: !isNaN(l) && isFinite(l) }
    },
  }
}
